import React from "react";
import "./blogs.css";

const Blogs = () => {
  return (
    <div className="page">
      <span style={{ backgroundColor: "white", padding: "1rem" }}>
        Coming soon
      </span>
    </div>
  );
};

export default Blogs;
