import React from "react";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";

import "./aboutme.css";

const AboutMe = () => {
  return (
    <div className="page">
      <div className="about-card">
        <div className="about-card-content">
          <div className="profile">
            <img src="/user_profile.jpg" alt="img" className="user-image" />
          </div>
          <h1 className="about-me-heading">About Me</h1>
          <p className="about-me-text">
            I am a fullstack software developer. I am currently working as a
            MERN developer. I Studied CSIT (computer science and information
            technology). I am interested in web development and all associated
            technologies.
          </p>
          <div className="links">
            <p>connect with me</p>
            <div className="link-icons">
              <a
                href="https://github.com/Sajandhakal12"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub className="icon" />
              </a>
              <a
                href="https://www.facebook.com/sajan.dhakal.10/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsFacebook className="icon" />
              </a>
              <a
                href="https://www.linkedin.com/in/sajan-dhakal-67408120b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillLinkedin className="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
