import React from "react";

const SkillCard = ({ icon, title, content }) => {
  return (
    <div className="skill-card">
      <div className="icon-container">
        <div className="icon-inner">
          <div className="inner-skew">
            <span>{icon}</span>
          </div>
        </div>
      </div>
      <div className="text-container">
        <h3>{title}</h3>
        <div>{content}</div>
      </div>
    </div>
  );
};

export default SkillCard;
