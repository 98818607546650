import React from "react";
import { useLayoutEffect, useRef } from "react";
import observer from "../_helpers/observer";
import "./education.css";

const Education = () => {
  const timelineRef = useRef(null);
  useLayoutEffect(() => {
    const children = timelineRef.current.children;
    if (children && children.length) {
      const length = children.length;
      for (let index = 0; index < length; index++) {
        observer.observe(children[index]);
      }
    }
  }, []);
  return (
    <div>
      <div className="container">
        <div className="timeline">
          <ul ref={timelineRef}>
            <li key={3}>
              <div className="timeline-content">
                <h1>Idea Breed Technoolgy</h1>
                <h5>09/2020 - present</h5>
                <p>
                  I am working as a full stack software developer in ideabreed
                  techolology. I am currently working as a MERN developer.
                </p>
                <div className="sub-timeline-items">
                  <div className="sub-timeline-item">
                    <p>
                      <span>Software Engineer</span>
                      <span>05/2021 - present</span>
                    </p>
                  </div>
                  <div className="sub-timeline-item">
                    <p>
                      <span>Associate Software Engineer</span>
                      <span>09/2021 - 05/2021</span>
                    </p>
                  </div>
                </div>
              </div>
            </li>

            <li key={2}>
              <div className="timeline-content">
                <h1>Madan Bhandari Memorial college</h1>
                <h5>08/2016 - 2021</h5>
                <p>
                  I studied my bachelor here. I did my bachelors in
                  CSIT(computer science and information techology)
                </p>
              </div>
            </li>

            <li key={1}>
              <div className="timeline-content">
                <h1>Everest International College</h1>
                <h5>06/2012 - 04/2014</h5>
                <p>I studied +2(11th and 12th) in this college</p>
              </div>
            </li>

            <li key={0}>
              <div className="timeline-content">
                <h1>Ashraya Vidhya Sadan</h1>
                <h5>2006 - 2012</h5>
                <p>
                  I studied from 4th grade to 10th grade in this school. I got
                  dintinction on my board.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Education;
