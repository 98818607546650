import React from "react";
import Navbar from "./Layouts/Navbar";
import { Switch, Route, withRouter } from "react-router-dom";

//pages
import AboutMe from "./Pages/AboutMe";
import Skills from "./Pages/Skills";
import Education from "./Pages/Education";
import Project from "./Pages/Project";
import Blogs from "./Pages/Blogs";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={AboutMe} />
        <Route path="/skills" component={Skills} />
        <Route path="/education" component={Education} />
        <Route path="/projects" component={Project} />
        <Route path="/blogs" component={Blogs} />
      </Switch>
    </>
  );
}
export default withRouter(App);
