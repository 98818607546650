import React, { useState } from "react";
import ProjectCard from "../components/ProjectCard";
import { DiOpensource } from "react-icons/di";
import { DiNodejs } from "react-icons/di";

import "./project.css";
import { FaReact } from "react-icons/fa";

const orientationData = {
  front: ["front", "right", "back", "left", "top", "bottom"],
  right: ["right", "back", "left", "front", "top", "bottom"],
  back: ["back", "left", "front", "right", "top", "bottom"],
  left: ["left", "front", "right", "back", "top", "bottom"],
  top: ["top", "right", "bottom", "left", "back", "front"],
  bottom: ["bottom", "left", "top", "right", "back", "front"],
};

const orientationMap = {
  front: (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FaReact size={36} color="#5CCFF8" />
      <span style={{ marginLeft: "10px" }}> ReactJS</span>
    </div>
  ),
  right: (
    <div style={{ display: "flex", alignItems: "center" }}>
      <DiNodejs size={36} color="#3AB346" />
      <span style={{ marginLeft: "10px" }}> NodeJS</span>
    </div>
  ),
  back: (
    <div style={{ display: "flex", alignItems: "center" }}>
      <DiOpensource size={36} color="#5CCFF8" />
      <span style={{ marginLeft: "10px" }}> OpenSource</span>
    </div>
  ),
  left: "",
  top: "",
  bottom: "",
};

export default function Project() {
  const [active, setActive] = useState("front");
  const [orientation, setOrientation] = useState(orientationData.front);

  const onChange = (e) => {
    setActive(e.target.value);
    setOrientation(orientationData[e.target.value]);
  };
  return (
    <>
      <div className="scene">
        <div className={`box show-${active}`}>
          <div className="box__face box__face--front">
            <h1>{orientationMap.front}</h1>
            <div className="project-cards">
              <ProjectCard
                title="Padhaku"
                content={
                  "Padhaku is e-commerce website for selling old books. It is built using reactjs, nodejs, express and socket.io. There are other features like chat system and forum for communication"
                }
                lineColor="blue"
                links={[{ type: "demo", url: "https://padhaku.netlify.app/" }]}
              />
              <ProjectCard
                title="Monkey Type Clone"
                lineColor="red"
                content={"Simple clone of monkey type website in react"}
                links={[
                  {
                    type: "code",
                    url: "https://github.com/Sajandhakal12/monkey-type-clone",
                  },
                  {
                    type: "demo",
                    url: "https://sajandhakal12.github.io/monkey-type-clone/",
                  },
                ]}
              />

              <ProjectCard
                title="Interactive Drawing"
                lineColor="violet"
                content={
                  "Real time drqwing using nextjs and nodejs for socket.io"
                }
                links={[
                  {
                    type: "code",
                    url: "https://github.com/Sajandhakal12/interactive-drawing-app",
                  },
                  {
                    type: "demo",
                    url: "https://interactive-drawing-app.vercel.app/",
                  },
                ]}
              />
            </div>
          </div>
          <div className="box__face box__face--back">
            <h1>{orientationMap.back}</h1>
            <div className="project-cards">
              <ProjectCard
                title="nepali-calendar-react"
                content={"A react library for nepali calendar."}
                lineColor="blue"
                links={[
                  {
                    type: "code",
                    url: "https://www.npmjs.com/package/nepali-calendar-react",
                  },
                  {
                    type: "demo",
                    url: "https://ideabreed.github.io/nepali-calendar-react/",
                  },
                ]}
              />

              <ProjectCard
                title="nep-num"
                content={
                  "A js library for converting english number system to nepali."
                }
                lineColor="black"
                links={[
                  {
                    type: "code",
                    url: "https://www.npmjs.com/package/nep-num",
                  },
                ]}
              />

              <ProjectCard
                title="react-nepali-input"
                content={"A react library for typing in nepali."}
                lineColor="green"
                links={[
                  {
                    type: "code",
                    url: "https://www.npmjs.com/package/react-nepali-input",
                  },
                ]}
              />
            </div>
          </div>
          <div className="box__face box__face--right">
            <h1>{orientationMap.right}</h1>
            <div className="project-cards">
              <ProjectCard
                title="Padhaku"
                content={
                  "Padhaku is e-commerce website for selling old books. It is built using reactjs, nodejs, express and socket.io. There are other features like chat system and forum for communication"
                }
                lineColor="blue"
                links={[{ type: "demo", url: "https://padhaku.netlify.app/" }]}
              />
            </div>
          </div>
          <div className="box__face box__face--left">
            <h1>{orientationMap.left}</h1>
          </div>
          <div className="box__face box__face--top">
            <h1>{orientationMap.top}</h1>
          </div>
          <div className="box__face box__face--bottom">
            <h1>{orientationMap.bottom}</h1>
          </div>
        </div>
      </div>
      <p className="radio-group">
        {orientation.map(
          (o) =>
            orientationMap[o] && (
              <label key={o} className="labels">
                <input
                  type="radio"
                  name="rotate-cube-side"
                  value={o}
                  checked={active === o}
                  onChange={onChange}
                />
                <span>{orientationMap[o]}</span>
              </label>
            )
        )}
      </p>
    </>
  );
}
