const observerOptions = {
  root: null,
  threshold: 0,
  rootMargin: "0px",
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) return;
    entry.target.classList.toggle("fade-in");
    observer.unobserve(entry.target);
  });
}, observerOptions);

export default observer;
