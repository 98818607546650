import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="navigation">
      <input
        type="checkbox"
        className={`navigation__checkbox ${open ? "open" : ""}`}
        id="navi-toggle"
        checked={open}
        onChange={() => setOpen((prev) => !prev)}
      />
      <label htmlFor="navi-toggle" className="navigation__button">
        <span className="navigation__icon"></span>
      </label>
      <div className={`navigation__background }`}>&nbsp;</div>
      <nav className={`navigation__nav }`}>
        <ul className="navigation__list">
          <li className="navigation__item" onClick={() => setOpen(false)}>
            <Link to="/" className="navigation__link">
              <span>01</span>About Me
            </Link>
          </li>
          <li className="navigation__item" onClick={() => setOpen(false)}>
            <Link to="/skills" className="navigation__link">
              <span>02</span>Skills
            </Link>
          </li>
          <li className="navigation__item" onClick={() => setOpen(false)}>
            <Link to="/education" className="navigation__link">
              <span>03</span>Experience
            </Link>
          </li>
          <li className="navigation__item" onClick={() => setOpen(false)}>
            <Link to="/projects" className="navigation__link">
              <span>04</span>Projects
            </Link>
          </li>
          <li className="navigation__item" onClick={() => setOpen(false)}>
            <Link to="/blogs" className="navigation__link">
              <span>05</span>Blogs
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
