import React from "react";
import { AiFillCode, AiFillEye } from "react-icons/ai";
import "./ProjectCard.css";

const ProjectCard = ({ title, content, links = [], lineColor }) => {
  return (
    <div className="project-card" style={{ "--line-color": lineColor }}>
      <h3 className="project-card-title">{title}</h3>
      <p className="project-card-content">{content}</p>
      <div className="links">
        {links.map((link) => (
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            title={link.type}
          >
            {link.type === "code" ? <AiFillCode /> : <AiFillEye />}
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProjectCard;
